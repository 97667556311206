<template>
  <section>
      <b-row>
          <b-col>
              <repeat-statistics :data="itemsData" size="3" />
          </b-col>
      </b-row>
      <b-row>
        <b-col>
          <ecualizador :metrics="metrics"></ecualizador>
        </b-col>
      </b-row>
  </section>
</template>

<script>
import { BRow, BCol, VBPopover, VBTooltip } from "bootstrap-vue";
import Screenshot from "@/views/clients/molymet/components/Screenshot";
import useApiServices from "@/services/useApiServices.js";
import axios from "@axios";
import RepeatStatistics from "@/views/clients/molymet/components/RepeatStatistics";
import Ecualizador from "./procedencias/Ecualizador.vue";
export default {
  name: "Histórico de Predicciones",
  components: {
      BRow,
      BCol,
      VBPopover,
      VBTooltip,
      Screenshot,
      VBPopover,
      VBTooltip,
      RepeatStatistics,
      Ecualizador,
  },
  data() {
      return {
          itemsData: [],
          metrics: [
            {'name':'ANT' , 'value':'ant', 'show': true},
            {'name':'COL' , 'value':'col','show':false, },
            {'name':'MCO' , 'value':'mco', 'show':false, },
            {'name':'MTE' , 'value':'mte', 'show':false, },
            {'name':'CJ' , 'value':'cj', 'show':false, },
            {'name':'DP' , 'value':'dp', 'show':false, },
            {'name':'MSA' , 'value':'msa', 'show':false, }
          ],
      };
  },

  directives: {
      "b-tooltip": VBTooltip,
      "b-popover": VBPopover,
  },
  // Repeat Statistics Cards
  mounted() {
      axios
          .get(useApiServices.historicoPrediccionesMolymetStatistics)
          .then((response) => {
              this.itemsData = response.data;
          })
          .catch((error) => { });
  },
};
</script>

<style lang="scss">

</style>