<template>
  <section>
    <!--  <b-card no-body>
      <div class="card-header">
        <div>
          <b-card-title>Procedencias</b-card-title>
          <b-card-sub-title class="mt-1 mb-2">Distribución de calidades analitos</b-card-sub-title>
        </div>
      </div>
      <b-card-body style="width: 100%">
        <b-row>
          <b-col lg="3" xl="3" v-for="metric in metrics" :key="metric.name">
            <b-form-checkbox v-model="metric.show" :value="true" :unchecked-value="false" switch>
              {{ metric.name }}
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card> -->
    <b-row>
      <b-col>
        <ag-grid-table ref="grid-list-predicciones" :configUrl="config" :dataUrl="data">
          <template v-slot:actions>
            <div>
              <div class="heading-elements">
                <ul class="list-inline text-right mb-1">
                  <li>
                    <feather-icon icon="HelpCircleIcon" v-b-toggle.sidebar-1 size="20" />
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <b-sidebar id="sidebar-1" title="Histórico predicciones" shadow bg-variant="dark" text-variant="light"
                width="450px" right backdrop>
                <div class="px-2 py-2">
                  <p>
                    En esta secci&oacute;n usted podr&aacute; prender y apagar las procedencias, esto con el fin de visualizar solo
                    las predicciones de las procedencias que desea.
                  </p>
                  <p>
                    En la tabla usted visualizar&aacute; los resultados de las predicciones diarias para las procedencias
                    abordadas en esta prueba de concepto.
                  </p>
                </div>
              </b-sidebar>
            </div>
          </template>
        </ag-grid-table>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, VBPopover, VBTooltip, BCardTitle, BCardSubTitle, BCardBody, BCard, } from "bootstrap-vue";
import AgGridTable from "@/views/brunacomponents/ag-grid-table/AgGridTable.vue";
import useApiServices from "@/services/useApiServices.js";
import axios from "@axios";
export default {
  name: "Histórico de Predicciones",
  components: {
    AgGridTable,
    BRow,
    BCol,
    VBPopover,
    VBTooltip,
    VBPopover,
    VBTooltip,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCard
  },
  data() {
    return {
      // itemsData: [],
      config: useApiServices.historicoPrediccionesConfig,
      data: useApiServices.historicoPrediccionesData,
      metrics: [
        { 'name': 'ANT', 'value': 'ant', 'show': true },
        { 'name': 'COL', 'value': 'col', 'show': false, },
        { 'name': 'MCO', 'value': 'mco', 'show': false, },
        { 'name': 'MTE', 'value': 'mte', 'show': false, },
        { 'name': 'CJ', 'value': 'cj', 'show': false, },
        { 'name': 'DP', 'value': 'dp', 'show': false, },
        { 'name': 'MSA', 'value': 'msa', 'show': false, }
      ],
    };
  },

  directives: {
    "b-tooltip": VBTooltip,
    "b-popover": VBPopover,
  },
  // Repeat Statistics Cards
  mounted() { },
};
</script>

<style lang="scss">

</style>
