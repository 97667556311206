<template>
  <section id="moly-historico">
    <b-tabs v-model="tabIndex">
      <!-- Tab Carga de Archivos -->
      <b-tab title="Dashboard Predicciones Diarias">
        <dashboard-predicciones></dashboard-predicciones>
      </b-tab>
      <!-- Tab Historico de Carga -->
      <b-tab title="Histórico Predicciones">
        <historico-predicciones></historico-predicciones>
      </b-tab>
    </b-tabs>
  </section>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback

} from "bootstrap-vue";
import DashboardPredicciones from "./DashboardPredicciones.vue";
import HistoricoPredicciones from "./HistoricoPredicciones.vue";

export default {
  data() {

    let activeTabName = this.$route.params.active_tab_name;
    let tabIndex = 0;

    if (activeTabName == "Dashboard Predicciones Diarias") {
      tabIndex = 0
    }

    if (activeTabName == "Histórico Predicciones") {
      tabIndex = 1
    }

    return {
      tabIndex: tabIndex
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,

    DashboardPredicciones,
    HistoricoPredicciones
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted() {

  },

  methods: {
    // updateHistorico() {
    //   this.$refs.historicoCarga.refreshInfo()
    // }
  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>

