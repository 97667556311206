<template>
  <b-card no-body>
    <div class="card-header">
      <!-- Title & SubTitle -->
      <div>
        <b-card-title>{{ $t(cardTitle) }}</b-card-title>
        <b-card-sub-title class="mb-2">{{ $t(cardSubTitle) }}</b-card-sub-title>
      </div>
      <!-- Card Actions -->
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <feather-icon icon="HelpCircleIcon" v-b-toggle.sidebar-1 size="20" />
          </li>
        </ul>
      </div>
    </div>
    <div>
      <b-sidebar id="sidebar-1" title="Dashboard Predicciones Diarias" shadow bg-variant="dark" text-variant="light"
        width="450px" right backdrop>
        <div class="px-2 py-2">
          <p>
            En esta secci&oacute;n usted podr&aacute; prender y apagar las procedencias, esto con el fin de visualizar
            solo los gr&aacute;ficos de las procedencias que desea.
          </p>
          <p>Dentro del gr&aacute;fico por omisi&oacute;n le aparecer&aacute; solo prendido el hist&oacute;rico real vs
            predicho de Molibdeno (Mo), sin embargo, para poder activar otros analitos a visualizar, debe prender o
            apagar dentro del gr&aacute;fico el analito deseado a observar.</p>
          <p>Adem&aacute;s, visualizara cuatro cuadros correspondientes a informaci&oacute;n que hemos considerado
            relevante poder visualizar.</p>
        </div>
      </b-sidebar>
    </div>

    <!-- Card Body  -->
    <b-card-body style="width: 100%">
      <b-row>
        <b-col lg="3" xl="3" v-for="metric in metrics" :key="metric.name">
          <b-form-checkbox v-model="metric.show" :value="true" :unchecked-value="false" switch>
            {{ metric.name }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="12" xl="12" v-for="metric in metrics" :key="metric.name" v-show="metric.show">

          <bruna-element-loader :dataUrl="baseUrl + '/' + metric.name"></bruna-element-loader>

        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>
  
<script>
import {
  BRow,
  BCol,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BCard,
  VBPopover,
  BFormInput,
  BFormCheckbox,
} from "bootstrap-vue";
const axios = require("axios");
import VueSlider from 'vue-slider-component';
import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue';
import useApiServices from "@/services/useApiServices.js";


export default {
  components: {
    BRow,
    BCol,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCard,
    VueSlider,
    BFormInput,
    BFormCheckbox,
    BrunaElementLoader
  },
  directives: {
  },
  data: function () {
    return {
      selected: [],
      baseUrl: useApiServices.chartProcedencia,
      options: this.metrics.map((metric) => { return { text: metric.name, value: metric } })
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
    simulate() {
      this.$emit('change')
    },
    searchChange(value) {
      this.$emit('search-change', value);
    },
    onDragEnd(metric) {
      console.log(metric + " drag_end")
    },
    onChange(metric, value) {
      /*
      if (this.timeout)
      {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() =>
      {
        this.$emit('change', metric, value);
      }, 3000); // delay
*/
    }
  },
  props: {
    metrics: {
      type: Array,
      required: true,
      default: () => []
    },
    cardTitle: {
      type: String,
      default: () => "Predicciones calidades diarias por procedencia"
    },
    cardSubTitle: {
      type: String,
      default: () => "Seleccione las procedencias que desea graficar"
    }
  },
};
</script>
  
<style lang="scss">

</style>


<style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>